<template>
  <div class="flex-col justify-start items-center page">
    <div class="grow"></div>

    <div
      v-html="currentSvg"
      class="image"
    ></div>

    <div class="grow"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      svgs: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.preloadSVGs().then(() => {
      this.startCarousel();
    });
  },
  beforeUnmount() {
    this.stopCarousel();
  },
  methods: {
    async preloadSVGs() {
      // 预加载 SVG 内容
      const svgPaths = ['/b1en.svg', '/b2en.svg'];
      const promises = svgPaths.map(path =>
        fetch(path).then(response => response.text())
      );
      this.svgs = await Promise.all(promises);
    },
    startCarousel() {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.svgs.length;
      }, 5000);
    },
    stopCarousel() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },
  computed: {
    currentSvg() {
      return this.svgs[this.currentIndex] || '';
    },
  },
};
</script>

<style scoped lang="css">
.page {
  background-color: #222222;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100vw;
  height: auto;
}
</style>
