import Vue from 'vue';
import VueRouter from 'vue-router';
import B from '../pages/b/b.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'b',
    component: B,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
