<template>
  <div class="flex-col justify-start page">
    <div style="width: 100vw;  height: auto;">
      <svg viewBox="0 0 1920 161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_95_3)">
          <path
            d="M124.292 50H119.714C116.055 50 112.709 52.1306 110.533 55.843L91.0883 89.0432C90.8415 89.4641 91.1414 89.9981 91.624 89.9981H97.3396C98.0856 89.9981 98.7749 89.5995 99.157 88.9504L116.329 59.6277C117.295 57.9811 118.754 57.0743 120.439 57.0743H123.564C125.25 57.0743 126.711 57.9811 127.674 59.6296L139.55 79.9068C140.15 80.9304 140.159 82.1617 139.575 83.1964C138.99 84.2311 137.873 84.8375 136.696 84.8375H126.12C124.561 84.8375 123.118 84.0049 122.321 82.6457L118.09 75.423C117.585 74.5626 116.357 74.5626 115.852 75.423L107.873 89.045C107.626 89.4659 107.926 90 108.409 90H152.376C152.859 89.9981 153.159 89.4641 152.912 89.0432L133.458 55.8171C131.293 52.1195 127.952 50 124.292 50Z"
            fill="white"/>
          <g filter="url(#filter0_b_95_3)">
            <rect x="400" y="30" width="1120" height="60" rx="12" fill="#3E3E3E" fill-opacity="0.6"/>
          </g>
          <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Alibaba PuHuiTi 3.0"
                font-size="29" font-weight="bold" letter-spacing="0.02em"><tspan x="570.391" y="70.44"><a href="#strengths" style="text-decoration: none;color:white">技术</a></tspan></text>
          <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Alibaba PuHuiTi 3.0"
                font-size="29" font-weight="bold" letter-spacing="0.02em"><tspan x="875.182" y="70.44"><a href="#services" style="text-decoration: none;color:white">服务</a></tspan></text>
          <text fill="white" xml:space="preserve" style="white-space: pre" font-family="Alibaba PuHuiTi 3.0"
                font-size="29" font-weight="bold" letter-spacing="0.02em"><tspan x="1191.47" y="70.44"><a href="#products" style="text-decoration: none;color:white">产品</a></tspan></text>
          <g @click="switch_to_cn" style="cursor: pointer;">
            <rect x="1700" y="50" width="200" height="100" fill="transparent"/>
            <path d="M1721.99 81.45C1720.34 81.45 1718.8 81.15 1717.36 80.55C1715.93 79.9333 1714.66 79.0917 1713.56 78.025C1712.48 76.9583 1711.63 75.7167 1711.01 74.3C1710.4 72.8833 1710.09 71.3667 1710.09 69.75C1710.09 68.1333 1710.4 66.6167 1711.01 65.2C1711.63 63.7833 1712.48 62.5417 1713.56 61.475C1714.66 60.4083 1715.93 59.575 1717.36 58.975C1718.8 58.3583 1720.34 58.05 1721.99 58.05C1723.47 58.05 1724.87 58.2917 1726.19 58.775C1727.5 59.2583 1728.68 59.9417 1729.71 60.825L1728.44 66.075C1727.82 65.0417 1726.97 64.175 1725.89 63.475C1724.8 62.7583 1723.55 62.4 1722.14 62.4C1720.79 62.4 1719.56 62.7333 1718.46 63.4C1717.38 64.05 1716.52 64.9333 1715.89 66.05C1715.25 67.15 1714.94 68.3833 1714.94 69.75C1714.94 71.1 1715.25 72.3333 1715.89 73.45C1716.52 74.5667 1717.38 75.4583 1718.46 76.125C1719.56 76.775 1720.79 77.1 1722.14 77.1C1723.54 77.1 1724.78 76.75 1725.86 76.05C1726.96 75.3333 1727.82 74.4583 1728.44 73.425L1729.71 78.675C1728.66 79.5417 1727.48 80.225 1726.16 80.725C1724.86 81.2083 1723.47 81.45 1721.99 81.45Z" fill="white"/>
            <path d="M1750.6 81.75L1737.1 68.275V81H1732.9V57.75L1746.4 71.2V58.5H1750.6V81.75Z" fill="white"/>
            <path d="M1801.15 81V58.5H1813.15V62.4H1805.5V65.85H1811.4V69.6H1805.5V76.95H1813.15V81H1801.15Z" fill="white" fill-opacity="0.5"/>
            <path d="M1834.6 81.75L1821.1 68.275V81H1816.9V57.75L1830.4 71.2V58.5H1834.6V81.75Z" fill="white" fill-opacity="0.5"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1791.8 53.3333L1772.49 90H1761.96L1760.2 86.6667L1779.51 50L1790.04 50L1791.8 53.3333Z" fill="white" fill-opacity="0.5"/>

          </g>
        </g>
        <defs>
          <filter id="filter0_b_95_3" x="380" y="10" width="1160" height="100" filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="10"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_95_3"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_95_3" result="shape"/>
          </filter>
          <clipPath id="clip0_95_3">
            <rect width="1920" height="161" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </div>
    <div class="flex-col section">


      <div class="grow"></div>
      <div class="flex-row justify-between items-center self-stretch group_2">
        <div class="flex-col items-start">
          <img class="image_3" src="../../assets/97aeaf1f45d1401d89f020cecac20c75.png"/>
          <span class="text_3 mt-31">让每个灵感，都成为游戏</span>
        </div>
        <div class="flex-col justify-start items-center relative group_3">
          <img class="image_5" src="../../assets/51c7ed3b486973bad18d91deab879c4e.png"/>
          <img class="image_4 pos" src="../../assets/3b40df0a0e9b83a316d6a2b41c1ca7d2.png"/>
        </div>
      </div>
      <div class="grow"></div>
      <img class="self-center image_6" src="../../assets/206a406aac64ae7618c89c74c1d84962.png"/>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {};
  },
  mounted() {

  },
  methods: {
    switch_to_cn() {
      console.log('切换到英文');
      window.language = 'en';
      // 触发全局事件，通知父组件语言已切换
      window.dispatchEvent(new CustomEvent('language-changed', { detail: 'en' }));
    },
  },
};
</script>

<style scoped lang="css">

.mt-31 {
  margin-top: 1.6146vw;
}

.page {
  background-color: #222222;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vh;
  background-image: url('../../assets/5d13fcf3b5b37cbc2ca5e815fb8a3529.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section {
  padding: 1.5625vw 4.7396vw 15vw;
  height: 100%;
}

.image {
  width: 3.2292vw;
  height: 2.0833vw;
}


.group_2 {
  padding: 0 7.7083vw;
}

.image_3 {
  width: 40.5208vw;
  height: 4.0625vw;
}

.text_3 {
  margin-left: 0.3125vw;
  color: #ffffff;
  font-size: 2.1354vw;
  font-family: Alibaba PuHuiTi;
  line-height: 2.037vw;
}

.group_3 {
  margin-right: 7.6563vw;
  padding: 0.9375vw 0;
  width: 8.5938vw;
}

.image_5 {
  border-radius: 0.1563vw;
  width: 0.9896vw;
  height: 1.7188vw;
}

.image_4 {
  width: 8.5938vw;
  height: 3.6458vw;
}

.pos {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.image_6 {
  border-radius: 0.1563vw;
  width: 1.7188vw;
  height: 0.9896vw;
}
</style>
