import Vue from 'vue';

import App from './app.vue';
import router from './router';

Vue.config.productionTip = false;


// 初始化全局语言状态
window.currentLanguage = 'en'; // 默认语言为英文

// 定义切换到中文的方法
window.switchToCn = function() {
  window.currentLanguage = 'cn';
  window.dispatchEvent(new Event('language-changed'));
};

// 定义切换到英文的方法
window.switchToEn = function() {
  window.currentLanguage = 'en';
  window.dispatchEvent(new Event('language-changed'));
};

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
