<!-- App.vue -->
<template>
  <div id="app">
    <!-- 移除固定的 <A> 组件 -->
    <!-- <A @switch-to-cn="switchToCn" @switch-to-en="switchToEn" /> -->

    <!-- 动态加载组件，根据当前语言选择相应的版本 -->
    <component :is="currentA" />
    <component :is="currentB" id="strengths" />
    <component :is="currentC" id="services" />
    <component :is="currentD" id="products" />

    <E />
  </div>
</template>


<script>
import A from '@/pages/a/a.vue';
import A_en from '@/pages/a/a_en.vue';
import B from '@/pages/b/b.vue';
import B_en from '@/pages/b/b_en.vue';
import C from '@/pages/c/c.vue';
import C_en from '@/pages/c/c_en.vue';
import D from '@/pages/d/d.vue';
import D_en from '@/pages/d/d_en.vue';
import E from '@/pages/e/e.vue';

export default {
  name: 'App',
  components: {
    A,
    A_en,
    B,
    B_en,
    C,
    C_en,
    D,
    D_en,
    E,
  },
  data() {
    return {
      language: window.language || 'cn', // 使用全局 window.language 或默认 'en'
    };
  },
  computed: {
    currentA() {
      return this.language === 'cn' ? 'A' : 'A_en';
    },
    currentB() {
      return this.language === 'cn' ? 'B' : 'B_en';
    },
    currentC() {
      return this.language === 'cn' ? 'C' : 'C_en';
    },
    currentD() {
      return this.language === 'cn' ? 'D' : 'D_en';
    },
  },
  mounted() {
    // 监听全局的语言切换事件
    window.addEventListener('language-changed', this.handleLanguageChange);
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听
    window.removeEventListener('language-changed', this.handleLanguageChange);
  },
  methods: {
    switchToCn() {
      window.language = 'cn';
      this.language = 'cn';
      console.log('语言切换到中文');
    },
    switchToEn() {
      window.language = 'en';
      this.language = 'en';
      console.log('语言切换到英文');
    },
    handleLanguageChange(event) {
      const newLanguage = event.detail;
      if (newLanguage === 'cn' || newLanguage === 'en') {
        this.language = newLanguage;
        console.log(`监听到语言切换到 ${newLanguage === 'cn' ? '中文' : '英文'}`);
      }
    },
  },
};
</script>


<style>

#app{
  width: 100vw;
  overflow-x: hidden;
}

    /************************************************************
  ** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
  ** 否则页面将无法正常显示                                  **
  ************************************************************/

  html {
    font-size: 16px;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', 'Microsoft Yahei', sans-serif;
  }

  body * {
    box-sizing: border-box;
    flex-shrink: 0;
  }

  #app {
    width: 100vw;
    height: 100vh;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .justify-start {
    justify-content: flex-start;
  }

  .justify-end {
    justify-content: flex-end;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-evenly {
    justify-content: space-evenly;
  }

  .items-start {
    align-items: flex-start;
  }

  .items-end {
    align-items: flex-end;
  }

  .items-center {
    align-items: center;
  }

  .items-baseline {
    align-items: baseline;
  }

  .items-stretch {
    align-items: stretch;
  }

  .self-start {
    align-self: flex-start;
  }

  .self-end {
    align-self: flex-end;
  }

  .self-center {
    align-self: center;
  }

  .self-baseline {
    align-self: baseline;
  }

  .self-stretch {
    align-self: stretch;
  }

  .flex-1 {
    flex: 1 1 0%;
  }

  .flex-auto {
    flex: 1 1 auto;
  }

  .grow {
    flex-grow: 1;
  }

  .grow-0 {
    flex-grow: 0;
  }

  .shrink {
    flex-shrink: 1;
  }

  .shrink-0 {
    flex-shrink: 0;
  }

  .relative {
    position: relative;
  }

  .ml-2 {
    margin-left: 0.1042vw;
  }

  .mt-2 {
    margin-top: 0.1042vw;
  }

  .ml-4 {
    margin-left: 0.2083vw;
  }

  .mt-4 {
    margin-top: 0.2083vw;
  }

  .ml-6 {
    margin-left: 0.3125vw;
  }

  .mt-6 {
    margin-top: 0.3125vw;
  }

  .ml-8 {
    margin-left: 0.4167vw;
  }

  .mt-8 {
    margin-top: 0.4167vw;
  }

  .ml-10 {
    margin-left: 0.5208vw;
  }

  .mt-10 {
    margin-top: 0.5208vw;
  }

  .ml-12 {
    margin-left: 0.625vw;
  }

  .mt-12 {
    margin-top: 0.625vw;
  }

  .ml-14 {
    margin-left: 0.7292vw;
  }

  .mt-14 {
    margin-top: 0.7292vw;
  }

  .ml-16 {
    margin-left: 0.8333vw;
  }

  .mt-16 {
    margin-top: 0.8333vw;
  }

  .ml-18 {
    margin-left: 0.9375vw;
  }

  .mt-18 {
    margin-top: 0.9375vw;
  }

  .ml-20 {
    margin-left: 1.0417vw;
  }

  .mt-20 {
    margin-top: 1.0417vw;
  }

  .ml-22 {
    margin-left: 1.1458vw;
  }

  .mt-22 {
    margin-top: 1.1458vw;
  }

  .ml-24 {
    margin-left: 1.25vw;
  }

  .mt-24 {
    margin-top: 1.25vw;
  }

  .ml-26 {
    margin-left: 1.3542vw;
  }

  .mt-26 {
    margin-top: 1.3542vw;
  }

  .ml-28 {
    margin-left: 1.4583vw;
  }

  .mt-28 {
    margin-top: 1.4583vw;
  }

  .ml-30 {
    margin-left: 1.5625vw;
  }

  .mt-30 {
    margin-top: 1.5625vw;
  }

  .ml-32 {
    margin-left: 1.6667vw;
  }

  .mt-32 {
    margin-top: 1.6667vw;
  }

  .ml-34 {
    margin-left: 1.7708vw;
  }

  .mt-34 {
    margin-top: 1.7708vw;
  }

  .ml-36 {
    margin-left: 1.875vw;
  }

  .mt-36 {
    margin-top: 1.875vw;
  }

  .ml-38 {
    margin-left: 1.9792vw;
  }

  .mt-38 {
    margin-top: 1.9792vw;
  }

  .ml-40 {
    margin-left: 2.0833vw;
  }

  .mt-40 {
    margin-top: 2.0833vw;
  }

  .ml-42 {
    margin-left: 2.1875vw;
  }

  .mt-42 {
    margin-top: 2.1875vw;
  }

  .ml-44 {
    margin-left: 2.2917vw;
  }

  .mt-44 {
    margin-top: 2.2917vw;
  }

  .ml-46 {
    margin-left: 2.3958vw;
  }

  .mt-46 {
    margin-top: 2.3958vw;
  }

  .ml-48 {
    margin-left: 2.5vw;
  }

  .mt-48 {
    margin-top: 2.5vw;
  }

  .ml-50 {
    margin-left: 2.6042vw;
  }

  .mt-50 {
    margin-top: 2.6042vw;
  }

  .ml-52 {
    margin-left: 2.7083vw;
  }

  .mt-52 {
    margin-top: 2.7083vw;
  }

  .ml-54 {
    margin-left: 2.8125vw;
  }

  .mt-54 {
    margin-top: 2.8125vw;
  }

  .ml-56 {
    margin-left: 2.9167vw;
  }

  .mt-56 {
    margin-top: 2.9167vw;
  }

  .ml-58 {
    margin-left: 3.0208vw;
  }

  .mt-58 {
    margin-top: 3.0208vw;
  }

  .ml-60 {
    margin-left: 3.125vw;
  }

  .mt-60 {
    margin-top: 3.125vw;
  }

  .ml-62 {
    margin-left: 3.2292vw;
  }

  .mt-62 {
    margin-top: 3.2292vw;
  }

  .ml-64 {
    margin-left: 3.3333vw;
  }

  .mt-64 {
    margin-top: 3.3333vw;
  }

  .ml-66 {
    margin-left: 3.4375vw;
  }

  .mt-66 {
    margin-top: 3.4375vw;
  }

  .ml-68 {
    margin-left: 3.5417vw;
  }

  .mt-68 {
    margin-top: 3.5417vw;
  }

  .ml-70 {
    margin-left: 3.6458vw;
  }

  .mt-70 {
    margin-top: 3.6458vw;
  }

  .ml-72 {
    margin-left: 3.75vw;
  }

  .mt-72 {
    margin-top: 3.75vw;
  }

  .ml-74 {
    margin-left: 3.8542vw;
  }

  .mt-74 {
    margin-top: 3.8542vw;
  }

  .ml-76 {
    margin-left: 3.9583vw;
  }

  .mt-76 {
    margin-top: 3.9583vw;
  }

  .ml-78 {
    margin-left: 4.0625vw;
  }

  .mt-78 {
    margin-top: 4.0625vw;
  }

  .ml-80 {
    margin-left: 4.1667vw;
  }

  .mt-80 {
    margin-top: 4.1667vw;
  }

  .ml-82 {
    margin-left: 4.2708vw;
  }

  .mt-82 {
    margin-top: 4.2708vw;
  }

  .ml-84 {
    margin-left: 4.375vw;
  }

  .mt-84 {
    margin-top: 4.375vw;
  }

  .ml-86 {
    margin-left: 4.4792vw;
  }

  .mt-86 {
    margin-top: 4.4792vw;
  }

  .ml-88 {
    margin-left: 4.5833vw;
  }

  .mt-88 {
    margin-top: 4.5833vw;
  }

  .ml-90 {
    margin-left: 4.6875vw;
  }

  .mt-90 {
    margin-top: 4.6875vw;
  }

  .ml-92 {
    margin-left: 4.7917vw;
  }

  .mt-92 {
    margin-top: 4.7917vw;
  }

  .ml-94 {
    margin-left: 4.8958vw;
  }

  .mt-94 {
    margin-top: 4.8958vw;
  }

  .ml-96 {
    margin-left: 5vw;
  }

  .mt-96 {
    margin-top: 5vw;
  }

  .ml-98 {
    margin-left: 5.1042vw;
  }

  .mt-98 {
    margin-top: 5.1042vw;
  }

  .ml-100 {
    margin-left: 5.2083vw;
  }

  .mt-100 {
    margin-top: 5.2083vw;
  }
</style>
