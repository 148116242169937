<template>
  <div class="flex-col relative page">
    <div v-html="svgContent" class="image"></div>
    <div class="grow"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      svgContent: '', // 用于存储预加载的 SVG 内容
    };
  },
  mounted() {
    this.preloadSVG();
  },
  methods: {
    async preloadSVG() {
      try {
        const response = await fetch('/c1118.svg');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.svgContent = await response.text();
      } catch (error) {
        console.error('加载 SVG 时出错:', error);
        // 可选：设置一个默认的 SVG 或显示错误消息
        this.svgContent = '<!-- SVG 加载失败 -->';
      }
    },
  },
};
</script>

<style scoped lang="css">
.page {
  background-color: #222222;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image {
  width: 100vw;
  height: auto;
}
</style>
