<template>
  <div class="flex-row items-start page">
    <div class="flex-col items-start flex-1 relative group">
      <div class="section"></div>
      <div class="flex-col justify-start items-start relative group_3">
        <img class="image_3" src="../../assets/fa0277fdbf0bf23052ab1b70ec4d46fc.png" />
        <img class="image_3 pos_2" src="../../assets/fa0277fdbf0bf23052ab1b70ec4d46fc.png" />
        <img class="image_3 pos_3" src="../../assets/fa0277fdbf0bf23052ab1b70ec4d46fc.png" />
        <img class="image_3 pos_4" src="../../assets/fa0277fdbf0bf23052ab1b70ec4d46fc.png" />
        <img class="image_3 pos_5" src="../../assets/fa0277fdbf0bf23052ab1b70ec4d46fc.png" />
      </div>
      <img
        v-show="showFirstImage"
        class="image_2 pos"
        src="../../assets/8686249df476dedb557fac718a0c5a94.png"
      />
      <img
        v-show="!showFirstImage"
        class="image_2 pos"
        src="../../assets/magiscope.png"
      />
    </div>
    <div class="flex-col group_2">
      <span v-show="showFirstImage" class="self-start text">MagiScapes</span>
      <span v-show="!showFirstImage" class="self-start text">MagiHub</span>
      <span v-show="showFirstImage" class="self-start text_2">MagiHub</span>
      <span v-show="!showFirstImage" class="self-start text_2">MagiScapes</span>
      <div class="self-stretch group_4">
        <span v-show="showFirstImage">
          <span class="font">All-in-one AI game creation platform——
            <br />
          </span>
          <span class="font">Create your unique masterpiece with MagiHub!</span>
        </span>
        <span v-show="!showFirstImage">
          <span class="font">MagiScapes: First AI sandbox game—</span>
          <br />
          <span class="font">Adventure with those you want to meet!</span>
        </span>
      </div>
    </div>
    <img class="image" src="../../assets/e9137fb5f41d00743aba8f911851cea0.png" />
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {
        showFirstImage: true,
      };
    },
    mounted() {
      setInterval(() => {
        this.showFirstImage = !this.showFirstImage;
      }, 5000);
    },

    methods: {},
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #222222;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
    padding-top: 2vw;
  }
  .group {
    margin-top: 9.0625vw;
    padding-top: 1.4583vw;
    overflow-x: hidden;
  }
  .section {
    margin-left: 16.25vw;
    background-color: #343434;
    width: 5.0521vw;
    height: 0.9375vw;
  }
  .group_3 {
    margin-left: -10.4167vw;
    margin-top: 19.7396vw;
    opacity: 0.2;
    width: 43.3333vw;
  }
  .image_3 {
    width: 14.375vw;
    height: 18.1771vw;
  }
  .pos_2 {
    position: absolute;
    left: 7.2917vw;
    bottom: 0;
  }
  .pos_3 {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  .pos_4 {
    position: absolute;
    right: 7.1875vw;
    bottom: 0;
  }
  .pos_5 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .image_2 {
    width: 51.3021vw;
    height: 32.7083vw;
  }
  .pos {
    position: absolute;
    right: 0;
    top: 0;
  }
  .group_2 {
    margin-left: 1.4583vw;
    margin-top: 30.5208vw;
    width: 18.8318vw;
  }
  .text {
    color: #474747;
    font-size: 1.5625vw;
    font-family: Afacad;
    font-weight: 700;
    line-height: 1.3562vw;
  }
  .text_2 {
    margin-left: 0.1042vw;
    margin-top: 0.9375vw;
    color: #ffffff;
    font-size: 2.6042vw;
    font-family: Afacad;
    font-weight: 700;
    line-height: 2.3146vw;
  }
  .group_4 {
    margin-top: 1.4583vw;
    line-height: 1.5625vw;
  }
  .font {
    font-size: 0.8vw;
    font-family: Alibaba PuHuiTi;
    line-height: 1.5625vw;
    color: #ffffff;
  }
  .font_2 {
    font-size: 1.0417vw;
    font-family: Afacad;
    line-height: 1.5625vw;
    color: #ffffff;
  }
  .image {
    margin-left: 8.9583vw;
    margin-right: 1.25vw;
    width: 4.4792vw;
    height: 43.0208vw;
  }
</style>
